import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import Button from '../button';
import { GIFTCARDS_QUERY } from '../../containers/coupon-codes';
import Input from '../input';
import Modal from '../modal';

const styles = {}

const CREATE_DISCOUNT_CODE_MUTATION = gql`
  mutation CreateDiscountCode($code: String!, $amountInCents: Int, $amountInPercent: Int, $startsAt: ISO8601DateTime, $endsAt: ISO8601DateTime) {
    createDiscountCode(input: { code: $code, amountInCents: $amountInCents, amountInPercent: $amountInPercent, startsAt: $startsAt, endsAt: $endsAt }) {
      discountCode {
        id
        code
        amountInCents
        amountInPercent
        startsAt
        endsAt
      }
    }
  }
`;

const UPDATE_DISCOUNT_CODE_MUTATION = gql`
  mutation UpdateDiscountCode($id: Int!, $code: String, $amountInCents: Int, $amountInPercent: Int, $startsAt: ISO8601DateTime, $endsAt: ISO8601DateTime) {
    updateDiscountCode(input: { id: $id, code: $code, amountInCents: $amountInCents, amountInPercent: $amountInPercent, startsAt: $startsAt, endsAt: $endsAt }) {
      discountCode {
        id
        code
        amountInCents
        amountInPercent
        startsAt
        endsAt
      }
    }
  }
`;

const CouponCodeForm = ({ discountCode, buttonLabel }) => {
  const [code, setCode] = useState(discountCode && discountCode.code);
  const [amountInCents, setAmountInCents] = useState(discountCode && discountCode.amountInCents);
  const [amountInPercent, setAmountInPercent] = useState(discountCode && discountCode.amountInPercent);
  const [startsAt, setStartsAt] = useState(discountCode && discountCode.startsAt);
  const [endsAt, setEndsAt] = useState(discountCode && discountCode.endsAt);

  const [createDiscountCode, { loading: createLoading, error: createError }] = useMutation(CREATE_DISCOUNT_CODE_MUTATION, {
    refetchQueries: [{ query: GIFTCARDS_QUERY }],
    awaitRefetchQueries: true,
    onCompleted() {
      window.location.reload();
    }
  });

  const [updateDiscountCode, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_DISCOUNT_CODE_MUTATION, {
    refetchQueries: [{ query: GIFTCARDS_QUERY }],
    awaitRefetchQueries: true,
    onCompleted() {
      window.location.reload();
    }
  });


  const handleSubmit = (e) => {
    e.preventDefault();

    const variables = {
      code, startsAt, endsAt,
      amountInCents: parseInt(amountInCents, 10),
      amountInPercent: parseInt(amountInPercent, 10)
    }

    discountCode ? updateDiscountCode({
      variables: { ...variables, id: discountCode.id }
    }) : createDiscountCode({
      variables: variables
    })
  }

  const loading = createLoading || updateLoading;
  const actualErrors = discountCode ? updateError : createError;

  const errors = actualErrors ? (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">There were {actualErrors.graphQLErrors.length} errors with your submission</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc pl-5 space-y-1">
              {actualErrors.graphQLErrors.map(({ message }, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  const headline = discountCode ? 'Coupon Code bearbeiten' : 'Neuen Coupon Code erstellen';

  return (
    <Modal buttonLabel={buttonLabel} title={headline}>
      <form className="space-y-4" onSubmit={handleSubmit} data-loading={loading}>
        {errors}
        <Input label='Code' value={code} onChange={setCode} placeholder='CHRISTMAS2020'/>
        <Input label='Wert in Cent' value={amountInCents} onChange={setAmountInCents} help='Es kann entweder ein Rabatt in Euro oder Prozent sein.' placeholder="Bspw. 2000 für 20€" type='number'/>
        <Input label='Wert in %' value={amountInPercent} onChange={setAmountInPercent} help='Es kann entweder ein Rabatt in Euro oder Prozent sein.' placeholder='Bspw. 20' type='number'/>
        <Input label='Gültig von' value={startsAt} onChange={setStartsAt} type='date' id="startsAt"/>
        <Input label='Gültig bis' value={endsAt} onChange={setEndsAt} type='date' id="endsAt"/>
        <Button type="submit">Speichern</Button>
      </form>
    </Modal>
  )
}

CouponCodeForm.propTypes = {
}

export default CouponCodeForm;
