import React, { Component } from 'react';

import Filter from '../../components/filter';
import KindergartenSubscriptionListing from '../../components/kindergarten-subscriptions/listing';
import Loading from '../../components/loading';
import Price from '../../components/layout/price';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const styles = {}

const GET_KINDERGARTEN_SUBSCRIPTIONS_QUERY = gql`
  query GetKindergartenSubscriptions {
    subscriptions: kindergartenSubscriptions {
      __typename
      id
      token
      status
      childName
      groupName
      createdAt
      customer {
        id
        firstname
        lastname
        email
      }
      kindergarten {
        id
        name
        city
      }
      productVariant {
        id
        priceInCents
        metafields {
          key
          value
        }
        product {
          id
          name
        }
      }
    }
  }
`;

class KindergartenSubscription extends Component {
  render() {
    const filter = this.props.match.params.filter ? this.props.match.params.filter : null;

    return (
      <div className={styles.container}>
        <Query key={filter} query={GET_KINDERGARTEN_SUBSCRIPTIONS_QUERY}>
          {(result) => {

            if (result.loading) {
              return <Loading/>;
            }

            if (result.error) {
              return (
                <div>
                  <h2>Fehler:</h2>
                  <div>{result.error}</div>
                </div>
              )
            }

            if (result.data) {
              const allSubscriptions = result.data && result.data.subscriptions;
              const activeSubscriptions = allSubscriptions.filter(sub => sub.status === "active");
              const pausedSubscriptions = allSubscriptions.filter(sub => sub.status === "paused");
              const canceledSubscriptions = allSubscriptions.filter(sub => sub.status === "canceled");
              const monthlyRevenue = activeSubscriptions.reduce(function(prev, cur) {
                return prev + cur.productVariant.priceInCents;
              }, 0);

              let filteredSubscriptions = allSubscriptions;
              if (filter !== null) {
                filteredSubscriptions = filteredSubscriptions.filter(sub => sub.status === filter);
              }

              return (
                <div>
                  <h1>Kiga-Abos ({allSubscriptions.length})</h1>
                  <Filter filter={[
                    { link: '/kindergarten-subscriptions', label: 'Alle' },
                    { link: '/kindergarten-subscriptions/filter/active', label: 'Aktiv' },
                    { link: '/kindergarten-subscriptions/filter/paused', label: 'Pausiert' },
                    { link: '/kindergarten-subscriptions/filter/canceled', label: 'Beendet' },
                    { link: '/kindergarten-subscriptions/filter/draft', label: 'Entwürfe' },
                  ]}/>
                  <div className={styles.status}>
                    <div className={styles.active} data-status="active">Aktive Abos: {activeSubscriptions.length}</div>
                    <div className={styles.paused} data-status="paused">Pausierte Abos: {pausedSubscriptions.length}</div>
                    <div className={styles.paused} data-status="canceled">Gekündigte Abos: {canceledSubscriptions.length}</div>
                  </div>
                  <div className={styles.metrics}>
                    <b>Anzahl aktive Kiga-Abos:</b> {activeSubscriptions.length}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;<b>Umsatz pro Monat:</b> <Price price={monthlyRevenue}/>
                  </div>
                  <KindergartenSubscriptionListing subscriptions={filteredSubscriptions}/>
                </div>
              )
            }

            return null;
          }}
        </Query>
      </div>
    );
  }
}

export default KindergartenSubscription;
