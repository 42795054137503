import Input from '../input'
import Loading from '../loading'
import React from 'react'
import {
  XCircleIcon
} from '@heroicons/react/solid'
import { classNames } from '../../helpers'

let inputTimeout;

const Table = ({
  loading=false,
  error,
  columns=[],
  items=[],
  renderItem=()=>{},
  searchable=false,
  onSearchInputChange=()=>{},
  searchQuery,
  searchInputPlaceholder="Please type to search",
}) => {
  if (loading) <Loading/>

  if (error) {
    console.error(error);
    return <div className="rounded-md bg-red-100 p-4 my-4 border-2 border-red-500">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-700">Beim Laden der Bestellungen ist ein Fehler aufgetreten. Melden sie sich bitte hier: andre@unknownbasics.com</h3>
        </div>
      </div>
    </div>
  }

  const onInputChange = (value) => {
    clearTimeout(inputTimeout);
    inputTimeout = setTimeout(function() {
      onSearchInputChange(value);
    }, 800);
  }

  return (
    <>
      { searchable &&
        <div className="relative">
          {searchQuery !== '' &&
            <button
              className="w-4 h-4 absolute right-2 top-2.5 text-gray-500 hover:text-indigo-500"
              onClick={() => onSearchInputChange("")}
            >
              <XCircleIcon/>
            </button>
          }
          <Input value={searchQuery} placeholder={searchInputPlaceholder} onChange={onInputChange}/>
        </div>
      }
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map(({ label, className }, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={classNames("px-3 py-3.5 text-left text-sm font-semibold text-gray-900 first:sm:pl-6", className)}
                      >{label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {items.map(item => renderItem(item)).map((col, index) => (
                    <tr key={index} className={classNames(
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                      "hover:bg-gray-100"
                    )}>
                      {col.map(({ content, className }, index) => (
                        <td key={index} className={classNames(className, "whitespace-nowrap px-3 py-4 text-sm text-gray-500 first:font-medium first:text-gray-900 first:sm:pl-6")}>{content}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Table
