import React, { createContext, useContext, useReducer } from 'react';

import Notification from '../components/notification';
import { createPortal } from 'react-dom';

export const NotificationContext = createContext();

const initialState = [];

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';

export const notificationReducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: +new Date(),
          content: action.payload.content,
          type: action.payload.type
        }
      ];
    case REMOVE:
      return state.filter(t => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};

export const NotificationProvider = props => {
  const [notification, notificationDispatch] = useReducer(notificationReducer, initialState);
  const notificationData = { notification, notificationDispatch };
  return (
    <NotificationContext.Provider value={notificationData}>
      {props.children}
      {createPortal(<Notification notification={notification} />, document.body)}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
