import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import { ExclamationIcon } from '@heroicons/react/outline'

const REFUND_ORDER_MUTATION = gql`
  mutation RefundOrderMutation($token: String!) {
    refundOrder(input: { orderToken: $token }) {
      order {
        token
        status
        transactions {
          id
          amountInCents
          braintreeId
          paymentMethod
          createdAt
          status
        }
      }
    }
  }
`;

export default function OrderRefund({ orderToken }) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  const [refundMutation, { loading, data, error }] = useMutation(REFUND_ORDER_MUTATION, {
    onCompleted() {
      window.location.reload()
    }
  })

  const refundMoney = () => {
    refundMutation({ variables: { token: orderToken } })
  }

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 self-start"
      >
        Geld erstatten
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Rückerstattung ausführen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Es kann nur der komplette Betrag erstattet werden. Eine Rückerstattung kann nicht rückgänging gemacht werden.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={refundMoney}
                    >
                      {loading && "In Bearbeitung..."}
                      {!loading && "Erstatten"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Abbrechen
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}


// <SidebarSection title="Erstattung">
//   <Mutation
//     mutation={REFUND_ORDER_MUTATION}
//     variables={{ token: orderToken }}
//     onCompleted={data => {
//       this.props.client.resetStore();
//     }}
//     onError={error => {
//       alert(error);
//     }}
//   >
//     {mutation => (
//       <form className={styles.form} onSubmit={ e => {
//           e.preventDefault();
//           mutation();
//         }}>
//         <div className={styles.addShipping} onClick={() => confirmRefund(mutation)}>Bestellung komplett erstatten</div>
//       </form>
//     )}
//   </Mutation>
// </SidebarSection>
//
//
