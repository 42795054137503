import * as Sentry from '@sentry/browser';

import React, { Component } from 'react';
const styles = {}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.container}>
          <img className={styles.gif} src="https://media.giphy.com/media/YyKPbc5OOTSQE/giphy.gif" alt=""/>
          <h2 className={styles.message}>Es ist ein Fehler aufgetreten. Wir haben eine Benachrichtigung erhalten und kümmern uns umgehend darum!</h2>
          <a onClick={() => Sentry.showReportDialog()}>Feedback senden</a>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
