import React, { Component } from 'react';

import GiftcardActions from '../../components/giftcard/actions'
import MomentDate from '../../components/date';
import { NavLink } from 'react-router-dom';
import Price from '../../components/layout/price'
import Status from '../../components/status'
import Table from '../../components/table'
import Title from '../../components/title'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'

const styles = {}

const GIFTCARDS_QUERY = gql`
  query Giftcards($status: String) {
    giftcards(status: $status) {
      id
      code
      amountInCents
      note
      redeemedAt
      createdAt
      customer {
        firstname
        lastname
      }
      redeemer {
        firstname
        lastname
      }
      receiptUrl
    }
  }
`;

// <div className={styles.filter}>
//   <NavLink exact activeClassName={styles.activeFilter} to='/giftcards'>Alle</NavLink>
//   <NavLink exact activeClassName={styles.activeFilter} to='/giftcards/filter/pending'>Offen</NavLink>
//   <NavLink exact activeClassName={styles.activeFilter} to='/giftcards/filter/redeemed'>Eingelöst</NavLink>
// </div>

export default function GiftCards() {
  const { loading, error, data } = useQuery(GIFTCARDS_QUERY, {
    fetchPolicy: 'network'
  })

  console.log('data', data)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title>Gutscheine</Title>
      </div>
      <Table
        loading={loading}
        error={error}
        columns={[
          { label: 'Code', className: "font-semibold text-gray-900" },
          { label: 'Erstellt am' },
          { label: 'Kunde' },
          { label: 'Eigelöst von' },
          { label: 'Status' },
          { label: 'Betrag' },
          { label: 'Aktionen' },
        ]}
        items={data?.giftcards}
        renderItem={(item) => {
          const amount = item.amountInCents ? <Price price={item.amountInCents}/> : `${item.amountInPercent}%`;
          return [
            { content: item.code },
            { content: <MomentDate date={item.createdAt} format="DD. MMMM YYYY - HH:mm"/> },
            { content: item.customer !== null && `${item.customer.firstname} ${item.customer.lastname}` },
            { content: item.redeemer !== null && `${item.redeemer.firstname} ${item.redeemer.lastname}` },
            { content: <Status status={item.redeemedAt ? "eingelöst" : "offen"}/> },
            { content: <Price price={item.amountInCents}/> },
            { content: <GiftcardActions giftcard={item}/> }
          ]
        }}
      />
    </div>
  )
}
