import React from 'react';
import { classNames } from '../../helpers'

export default function Button ({ children, size="normal", confirm=false, confirmMessage="Sicher?", onClick=()=>{}, loading}) {
  const onLocalClick = () => {
    if (confirm) {
      if (window.confirm(confirmMessage) == true) {
        onClick()
      }
    } else {
      onClick()
    }
  }

  const label = loading ? (
    <div className="flex items-center space-x-3">
      <svg className="animate-spin -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <div>Lädt...</div>
    </div>
  ) : children

  return (
    <button
      onClick={onLocalClick}
      className={classNames(
        size === "small" ? "text-xs" : "",
        "inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      )}
    >{label}</button>
  )
}
