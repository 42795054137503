import Comment from './comment'
import CommentForm from './form'
import React from 'react'

export default function Comments({ commentableId, comments=[], currentUserId, orderToken }) {
  return (
    <section aria-labelledby="notes-title">
      <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
              Notizen
            </h2>
          </div>
          <div className="px-4 py-6 sm:px-6">
            {comments.length === 0 &&
              <div className="font-medium text-gray-900">Keine Notizen</div>
            }
            {comments.length > 0 &&
              <ul role="list" className="space-y-8">
                {comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    currentUserId={currentUserId}
                    orderToken={orderToken}
                  />
                ))}
              </ul>
            }
          </div>
        </div>
        <CommentForm
          commentableId={commentableId}
          orderToken={orderToken}
        />
      </div>
    </section>
  )
}
