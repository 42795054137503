import React, { Component } from 'react';

const styles = {}

export class Columns extends Component {
  render() {
    return (
      <div className={styles.columns}>
        {this.props.children}
      </div>
    );
  }
}

export class Content extends Component {
  render() {
    return (
      <div className={styles.content}>
        {this.props.children}
      </div>
    );
  }
}

export class ContentSection extends Component {
  render() {
    return (
      <div className={styles.contentSection}>
        {( this.props.title &&
          <div className={styles.contentSectionTitle}>{this.props.title}</div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export class Sidebar extends Component {
  render() {
    return (
      <div className={styles.sidebar}>
        {this.props.children}
      </div>
    );
  }
}

export class SidebarSection extends Component {
  render() {
    return (
      <div className={styles.sidebarSection}>
        {( this.props.title &&
          <div className={styles.sidebarSectionTitle}>{this.props.title}</div>
        )}
        {this.props.children}
      </div>
    );
  }
}
