import React from 'react'

// import { useHistory } from "react-router-dom"

const styles = {}

const Pagination = ({ page, setPage, lastPage=false }) => {
  // const history = useHistory()

  const goToPreviousPage = () => {
    goToPage(page - 1)
    // history.replace("/ewfjewfjkwhefkw ")
  }

  const goToNextPage = () => {
    goToPage(page + 1)
  }

  const goToPage = (page) => {
    setPage(page)
    // console.log(location);
    // history.replace("/ ")
  }

  return (
    <nav
      className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="flex-1 flex justify-center md:justify-end">
        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" onClick={goToPreviousPage} disabled={page === 1}>
          Zurück
        </button>
        <button className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50" onClick={goToNextPage} disabled={lastPage}>
          Weiter
        </button>
      </div>
    </nav>
  )
}

export default Pagination
