import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client'

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      user {
        authenticationToken
      }
    }
  }
`;

export default function LoginForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState(null)
  const [logUserIn, { loading }] = useMutation(LOGIN_MUTATION)

  const onSubmit = (e) => {
    e.preventDefault()
    setMessage(null)
    logUserIn({
      variables: { email, password },
      onError: error => {
        setMessage('E-Mail und Passwort stimmen nicht überein.')
      },
      onCompleted: data => {
        if (data.loginUser === null) {
        } else {
          localStorage.setItem('cashierUserToken', data.loginUser.user.authenticationToken);
          window.location.reload();
        }
      }
    })
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src="https://www.hedda-baby.com/static/logo-a67fa585982a85da661204d3649530e6.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

            {( message &&
              <div className="rounded-md bg-red-200 p-4 mb-4">
                <div className="flex">
                  <div className="ml-3">
                    <div className="text-sm text-red-700">
                      <p>
                        {message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <form className={`space-y-6 ${loading && 'opacity-5 pointer-events-none'}`} onSubmit={onSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="E-Mail"
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="Passwort"
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={email === '' && password === ''}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Anmelden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

// <div className={"styles.login"}>
//   <h1 className={"styles.id"}>HEDDA Baby</h1>
//   <p>Bitte melde dich an</p>
  // {( message &&
  //   <div className={"styles.message"}>{message}</div>
  // )}
//   <form className={"styles.form"} >

//     <button
//       className={"styles.button"}

//     >Anmelden</button>
//   </form>
// </div>
