import React from 'react';
import TransactionListingItem from './listingItem';

export default function TransactionListing({ transactions }) {
  return (
    <dd className="mt-1 text-sm text-gray-900">
      <div className="border border-gray-200 rounded-md divide-y divide-gray-200">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 rounded-tl-md">
                Betrag
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Typ
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Braintree Id
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 rounded-tr-md">
                Datum
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {transactions.map((transaction) => (
              <TransactionListingItem key={transaction.id} transaction={transaction}/>
            ))}
          </tbody>
        </table>
      </div>
    </dd>
  )
}
