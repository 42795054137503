import 'moment/locale/de';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

moment.locale('de');

export default function MomentDate({ date, format, as="div" }) {
  const Tag = as
  if (format === undefined) {
    format = "DD. MMMM YYYY";
  }

  const dateString = moment(date).format(format);

  return (
    <Tag>{dateString}</Tag>
  )
}
