import React, { useEffect, useState } from 'react';

import Filter from '../../components/filter';
import Input from '../../components/input'
import Loading from '../../components/loading';
import MomentDate from '../../components/date';
import { NavLink } from 'react-router-dom';
import Pagination from '../../components/pagination'
import Price from '../../components/layout/price';
import Status from '../../components/status';
import Table from '../../components/table'
import Title from '../../components/title'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'

const styles = {}

const ORDERS_QUERY = gql`
  query OrdersQuery($status: String, $limit: Int, $skip:Int) {
    orders(status: $status, limit: $limit, skip: $skip) {
      token
      number
      status
      totalPriceInCents
      createdAt
      updatedAt
      isSubscription
      customer {
        firstname
        lastname
      }
    }
  }
`;

const ORDERS_SEARCH_QUERY = gql`
  query OrdersSearchQuery($query: String!) {
    orders: searchOrders(query: $query) {
      token
      number
      status
      totalPriceInCents
      createdAt
      updatedAt
      isSubscription
      customer {
        firstname
        lastname
      }
    }
  }
`;

const Orders = ({ match }) => {
  const filter = match.params.filter ? match.params.filter : null;
  const limit = 50
  const [page, setPage] = useState(1)
  const skip = limit * (page - 1)
  const [searchQuery, setSearchQuery] = useState('')

  const query = searchQuery === '' ? ORDERS_QUERY : ORDERS_SEARCH_QUERY
  const { loading, error, data } = useQuery(query, {
    variables: { status: filter, limit, skip, query: searchQuery },
    fetchPolicy: 'network'
  })

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }, [page, loading])

  return (
    <div className={styles.container}>
      <Title>Bestellungen</Title>
      {( searchQuery === '' &&
        <Filter filter={[
          { link: '/orders', label: 'Alle' },
          { link: '/orders/filter/pending', label: 'Ausstehend' },
          { link: '/orders/filter/fullfilled', label: 'Versandt' },
          { link: '/orders/filter/cart', label: 'Warenkörbe' },
        ]}/>
      )}
      <Table
        searchable
        searchQuery={searchQuery}
        onSearchInputChange={setSearchQuery}
        searchInputPlaceholder="Suchbegriff hier eingeben. Bspw: Name, Datum (YYYY-MM-DD) oder Bestellnummer (HEDDA-XXXX)"
        loading={loading}
        error={error}
        columns={[
          { label: 'Nummer', className: "font-semibold text-gray-900" },
          { label: 'Aktualisiert am' },
          { label: 'Kunde' },
          { label: 'Status' },
          { label: 'Abo' },
          { label: 'Betrag' },
        ]}
        items={data?.orders}
        renderItem={(item) => {
          return [
            { content: <NavLink to={`/orders/${item.token}`}>{item.number}</NavLink>, className: "" },
            { content: <>
              <MomentDate date={item.updatedAt} format="DD. MMMM YYYY - HH:mm"/>
              <div className="text-xs">
                <MomentDate date={item.createdAt} format="DD. MMMM YYYY - HH:mm"/>
              </div>
            </> },
            { content: item.customer !== null && `${item.customer.firstname} ${item.customer.lastname}` },
            { content: <Status status={item.status}/> },
            { content: item.isSubscription ? 'Ja' : '-' },
            { content: <Price price={item.totalPriceInCents}/>, className: "text-gray-900" },
          ]
        }}
      />
      {searchQuery === '' && data?.orders?.length === limit && <Pagination page={page} lastPage={data?.orders.length < limit} setPage={setPage}/>}
    </div>
  );
}

export default Orders;
