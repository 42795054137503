import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import Modal from '../modal'

const ADD_TRACKINGINFORMATION_TO_ORDER_MUTATION = gql`
  mutation AddTrackingInformationToOrder($token: String!, $trackingCode: String!) {
    addTrackingInformationToOrder(input: { token: $token, trackingCode: $trackingCode }) {
      order {
        token
        trackingCode
      }
    }
  }
`;

export default function OrderTracking({ orderToken, trackingCode }) {
  const [code, setCode] = useState(trackingCode || "")
  const [addTracking, { loading, data, error }] = useMutation(ADD_TRACKINGINFORMATION_TO_ORDER_MUTATION, {
    onCompleted() {
      window.location.reload()
    }
  })

  const onFormSubmit = (e) => {
    e.preventDefault()

    addTracking({ variables: { token: orderToken, trackingCode: code } })
  }

  return (
    <Modal
      title="Order Tracking"
      buttonLabel="Tracking hinzufügen"
    >
      <form className="space-y-6" onSubmit={onFormSubmit}>
        <div>
          <label htmlFor="code" className="block text-sm font-medium text-gray-700">
            Tracking Code
          </label>
          <div className="mt-1">
            <input
              value={code}
              id="cpde"
              name="code"
              type="text"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Speichern
          </button>
        </div>
      </form>
    </Modal>
  )
}
