import { gql, useMutation } from '@apollo/client'

import CommentForm from './form'
import MomentDate from '../date'
import { ORDER_QUERY } from '../../containers/order'
import React from 'react'

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(input: { id: $id }) {
      comment {
        id
      }
    }
  }
`;

export default function Comments({ comment, currentUserId, orderToken }) {
  const [removeComment, { loading, data, error }] = useMutation(DELETE_COMMENT_MUTATION)
  const deleteComment = () => {
    if (confirm("Soll die Notiz gelöscht werden?") == true) {
      removeComment({
        variables: { id: comment.id },
        update: (cache, { data: { deleteComment } }) => {
          const data = cache.readQuery({
            query: ORDER_QUERY,
            variables: { token: orderToken }
          });

          const newData = {
            ...data,
            order: {
              ...data.order,
              comments: data.order.comments.filter(c => c.id !== deleteComment.comment.id)
            }
          }

          cache.writeQuery({
            query: ORDER_QUERY,
            data: newData,
            variables: { token: orderToken }
          });
        }
      })
    }
  }
  return (
    <li key={comment.id}>
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={`https://images.unsplash.com/photo-1569172122301-bc5008bc09c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&h=256&q=80`}
            alt=""
          />
        </div>
        <div>
          <div className="text-sm">
            <div className="font-medium text-gray-900">
              {comment.user.firstname} {comment.user.lastname}
            </div>
          </div>
          <div className="mt-1 text-sm text-gray-700">
            <p>{comment.body}</p>
          </div>
          <div className="mt-2 text-sm space-x-2">
            <span className="text-gray-500 font-medium">
              <MomentDate date={comment.createdAt} as="span" format="DD. MMMM YYYY HH:mm"/>
            </span>{' '}
            {comment.user.id === currentUserId &&
              <>
                <span className="text-gray-500 font-medium">&middot;</span>{' '}
                <button type="button" className="text-gray-500 hover:text-gray-900 font-medium" onClick={deleteComment}>
                  Löschen
                </button>
              </>
            }
          </div>
        </div>
      </div>
    </li>
  )
}
