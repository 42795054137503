import React, { Component } from 'react';

const styles = {}

class Footer extends Component {
  render() {
    return (
      <footer className="mt-10 mb-4 text-center text-sm text-gray-500">
        <div className={styles.container}>
          This is an UNKNOWN STUDIOS service
        </div>
      </footer>
    );
  }
}
export default Footer;
