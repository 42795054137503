import React, { Component } from 'react';

class Price extends Component {
  render() {
    const priceInCurrency = `${(this.props.price / 100).toFixed(2)}€`;

    return (
      <span>{priceInCurrency}</span>
    );
  }
}

export default Price;
