import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { classNames } from '../../helpers'

const styles = {}

class OrderStatus extends Component {
  render() {
    const { status } = this.props;

    if (status === null || status === undefined) {
      return 'unknown';
    }

    return (
      <span className={classNames(
        "capitalize inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
        status === "pending" && "bg-indigo-100 text-indigo-800",
        status === "paused" && "bg-indigo-100 text-indigo-800",
        status === "cart" && "bg-orange-100 text-orange-800",
        status === "failed" && "bg-red-100 text-red-800",
        status === "fullfilled" && "bg-green-100 text-green-800",
        status === "preparing" && "bg-indigo-100 text-indigo-800",
        status === "active" && "bg-green-100 text-green-800",
        status === "inactive" && "bg-gray-100 text-gray-800",
        status === "offen" && "bg-orange-100 text-orange-800",
        status === "canceled" && "bg-orange-100 text-orange-800",
        status === "eingelöst" && "bg-green-100 text-green-800",
        status === "refunded" && "bg-gray-100 text-gray-800",
        this.props.className,
      )}>
        {status}
      </span>
    );
  }
}

OrderStatus.propTypes = {
  status: PropTypes.string.isRequired
}

export default OrderStatus;
