import { gql, useQuery } from '@apollo/client'

import CustomerComment from './comment'
import React from 'react'

const CUSTOMER_QUERY = gql`
  query CustomerQuery($id: ID!){
    customer(id: $id) {
      uuid
      id
      braintreeId
      firstname
      lastname
      email
      comments {
        id
        uuid
        body
      }
      defaultPaymentMethod {
        imageUrl
        verified
      }
    }
  }
`;

const CustomerModalContent = ({ id }) => {
  const {data, loading, error} = useQuery(CUSTOMER_QUERY, {
    variables: { id }
  })

  if (loading) {
    return <div>Kundendaten werden geladen...</div>
  }

  if (error) {
    return <div>Es ist ein Fehler aufgetreten.</div>
  }

  const { customer } = data

  return (
    <div className="text-gray-500 space-y-3">
      <div>
        <dt className="font-medium text-gray-900">Id</dt>
        <dd className="text-gray-500">
          {customer.id}
        </dd>
      </div>
      <div>
        <dt className="font-medium text-gray-900">Name</dt>
        <dd className="text-gray-500">
          {customer.firstname} {customer.lastname}
        </dd>
      </div>
      <div>
        <dt className="font-medium text-gray-900">E-Mail</dt>
        <dd className="text-gray-500">
          {customer.email}
        </dd>
      </div>
      <div>
        <dt className="font-medium text-gray-900">Braintree Id</dt>
        <dd className="text-gray-500">
          {customer.braintreeId}
        </dd>
      </div>
      {( customer.defaultPaymentMethod &&
        <div>
          <dt className="font-medium text-gray-900">Standard-Bezahlmethode</dt>
          <dd className="text-gray-500">
            <div className="flex space-x-3">
              Typ: <img src={customer.defaultPaymentMethod.imageUrl} style={{ width: 30 }} className="ml-2" alt=""/>
            </div>
            <div>
              Verifiziert: {customer.defaultPaymentMethod.verified ? 'Ja' : 'Nein'}
            </div>
          </dd>
        </div>
      )}
      <div>
        <dt className="font-medium text-gray-900">Kundennotiz</dt>
        <CustomerComment
          commentableId={customer.uuid}
          comment={customer.comments[0]}
        />
      </div>
    </div>
  )
}

export default CustomerModalContent
