import CouponCodeForm from '../../components/coupon-code/form';
import Loading from '../../components/loading';
import MomentDate from '../../components/date';
import Price from '../../components/layout/price'
import { Query } from 'react-apollo';
import React from 'react';
import Status from '../../components/status'
import Table from '../../components/table'
import Title from '../../components/title'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'

const styles = {}

export const GIFTCARDS_QUERY = gql`
  {
    discountCodes {
      id
      code
      amountInCents
      amountInPercent
      canBeUsed
      createdAt
      startsAt
      endsAt
      usedTimes
    }
  }
`;

export default function GiftCards() {
  const { loading, error, data } = useQuery(GIFTCARDS_QUERY, {
    fetchPolicy: 'network'
  })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title
          actions={[<CouponCodeForm buttonLabel="Code erstellen"/>]}
        >Coupon Codes</Title>
      </div>
      <Table
        loading={loading}
        error={error}
        columns={[
          { label: 'Code', className: "font-semibold text-gray-900" },
          { label: 'Gültig von' },
          { label: 'Gültig bis' },
          { label: 'Höhe' },
          { label: 'Verwendet' },
          { label: 'Status' },
          { label: 'Aktionen' },
        ]}
        items={data?.discountCodes}
        renderItem={(item) => {
          const amount = item.amountInCents ? <Price price={item.amountInCents}/> : `${item.amountInPercent}%`;
          return [
            { content: item.code },
            { content: item.startsAt ? <MomentDate date={item.startsAt} format="DD. MMMM YYYY - HH:mm"/> : 'Nicht definiert' },
            { content: item.endsAt ? <MomentDate date={item.endsAt} format="DD. MMMM YYYY - HH:mm"/> : 'Nicht definiert' },
            { content: amount },
            { content: item.usedTimes },
            { content: <Status status={item.canBeUsed ? "active" : "inactive"}/> },
            { content: <CouponCodeForm discountCode={item} buttonLabel="Bearbeiten"/> },
          ]
        }}
      />
    </div>
  )
}
