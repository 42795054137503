import MomentDate from '../date';
import Price from '../layout/price';
import React from 'react';

export default function Transaction({ transaction }) {
  return (
    <tr key={transaction.id} className="hover:bg-gray-100 even:bg-gray-50">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Price price={transaction.amountInCents}/>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {transaction.paymentMethod}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <b>{transaction.braintreeId}</b>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <Price price={transaction.amountInCents}/>
      </td>
    </tr>
  )
}
