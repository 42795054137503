import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CouponCodes from '../coupon-codes';
import CustomerSubscriptions from '../customer-subscriptions';
import ErrorBoundary from '../errors/500';
import Footer from '../../components/footer';
import GiftCards from '../giftcards'
import Header from '../../components/header';
import KindergartenSubscription from '../kindergarten-subscriptions';
import LoginForm from '../../components/login';
import { NotificationProvider } from '../../contexts/notification-context'
import Order from '../order';
import Orders from '../orders';
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

class App extends Component {
  render() {
    const userToken = localStorage.getItem('cashierUserToken');

    if (userToken === null || userToken === undefined) {
      return (
        <LoginForm/>
      )
    }

    return (
      <NotificationProvider>
        <Header/>
        <ErrorBoundary>
          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <Switch>
                <Redirect exact from='/' to='/orders'/>
                <Route exact path='/orders' component={Orders}/>
                <Route exact path='/orders/page/:page' component={Orders}/>
                <Route exact path='/orders/filter/:filter' component={Orders}/>
                <Route exact path='/orders/filter/:filter/page/:page' component={Orders}/>
                <Route exact path='/orders/:token' component={Order}/>
                <Route exact path='/customer-subscriptions' component={CustomerSubscriptions}/>
                <Route exact path='/kindergarten-subscriptions' component={KindergartenSubscription}/>
                <Route exact path='/kindergarten-subscriptions/filter/:filter' component={KindergartenSubscription}/>
                <Route exact path='/giftcards' component={GiftCards}/>
                <Route exact path='/giftcards/filter/:filter' component={GiftCards}/>
                <Route exact path='/coupon-codes' component={CouponCodes}/>
              </Switch>
              {/* /End replace */}
            </div>
          </main>
        </ErrorBoundary>
        <Footer/>
      </NotificationProvider>
    );
  }
}

export default App;
