import ListingItem from './listingItem';
import React from 'react';

export default function OrderLineItemsListing({ items }) {
  return (
    <dd className="mt-1 text-sm text-gray-900">
      <div className="border border-gray-200 rounded-md divide-y divide-gray-200">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 rounded-tl-md">
                Produkt
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                SKU
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 rounded-tr-md">
                Anzahl
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {items.map((item) => (
              <ListingItem key={item.id} item={item}/>
            ))}
          </tbody>
        </table>
      </div>
    </dd>
  )
}
