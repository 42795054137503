import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Columns, Content, ContentSection, Sidebar, SidebarSection } from '../../components/columns';
import { Menu, Popover, Transition } from '@headlessui/react'
import { Mutation, Query, withApollo } from 'react-apollo';
import React, { useState } from 'react';

import Comments from '../../components/comments'
import CustomerComment from '../../components/customer/comment'
import Errors from '../../components/errors';
import Loading from '../../components/loading';
import MomentDate from '../../components/date';
import OrderLineItemsListing from '../../components/orderLineItems/listing';
import OrderRefund from '../../components/order/refund'
import OrderTracking from '../../components/order/tracking'
import Price from '../../components/layout/price';
import Status from '../../components/status';
import TransactionListing from '../../components/transactions/listing';
import { classNames } from '../../helpers'
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'

export const ORDER_QUERY = gql`
  query Order($token: String!) {
    currentUser {
      id
    }
    order(token: $token) {
      id: uuid
      token
      number
      status
      totalPriceInCents
      createdAt
      updatedAt
      placedAt
      trackingCode
      shippingAddressName1
      shippingAddressName2
      shippingAddressName3
      shippingAddressStreet
      shippingAddressZip
      shippingAddressCity
      firstOrder
      comment
      comments {
        id
        body
        createdAt
        user {
          id
          firstname
          lastname
        }
      }
      customer {
        uuid
        firstname
        lastname
        email
        comments {
          uuid
          body
        }
      }
      discountCode {
        code
        amountInCents
        amountInPercent
      }
      savings
      transactions {
        id
        amountInCents
        braintreeId
        paymentMethod
        createdAt
      }
      items {
        id
        quantity
        productVariant {
          sku
          product {
            name
          }
          options {
            name
            value
          }
        }
      }
    }
  }
`;



// TODO: discount code fehlt
//

export default function Order({ match: { params: { token }} }) {
  const [trackingCode, setTrackingCode] = useState()
  const { loading, error, data } = useQuery(ORDER_QUERY, {
    variables: { token },
    fetchPolicy: 'network'
  })

  if (loading) return <Loading/>
  if (error) {
    console.info(error)
    return <Error errors={error}/>
  }

  const { order, currentUser } = data
  const orderToken = order.token

  return (
    <main className="py-10">
      {/* Page header */}
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-12">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{order?.number}</h1>
            <p className="text-sm font-medium text-gray-500">
              Erstellt am <MomentDate as="span" date={order.createdAt} format="DD. MMMM YYYY"/> um <MomentDate as="span" date={order.createdAt} format="HH:mm"/>.
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        {['pending', 'fullfilled'].includes(order.status) &&
          <OrderTracking orderToken={orderToken} trackingCode={order?.trackingCode}/>
        }
        </div>
      </div>

      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">

          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-4 sm:px-6 flex justify-between items-center">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Order Information
                </h2>
                <Status status={order.status} className="text-lg px-4 py-1.5"/>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                {order.firstOrder &&
                  <div className="rounded-md bg-indigo-100 p-4 mb-6">
                    <div className="flex">
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-indigo-700">Das ist die erste Bestellung von {order?.customer?.firstname}. 🎉</p>
                      </div>
                    </div>
                  </div>
                }

                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="font-medium text-gray-500">Lieferadresse</dt>
                    <dd className="mt-1 text-gray-900">
                      {((order.shippingAddressCity !== null, order.shippingAddressName !== null, order.shippingAddressZip !== null, order.shippingAddressStreet !== null) &&
                        <>
                          <div className="font-bold">
                            {order.shippingAddressName1}
                          </div>
                          <div>
                            {order.shippingAddressName2}
                          </div>
                          <div>
                            {order.shippingAddressName3}
                          </div>
                          <div>
                            {order.shippingAddressStreet}
                          </div>
                          <div>
                            {order.shippingAddressZip} {order.shippingAddressCity}
                          </div>
                        </>
                      )}
                      {((order.shippingAddressCity === null, order.shippingAddressName === null, order.shippingAddressZip === null, order.shippingAddressStreet === null) &&
                        <div>Der Kunde hat keine Lieferadresse angegeben.</div>
                      )}
                    </dd>
                  </div>
                  {order?.trackingCode &&
                    <div className="sm:col-span-1">
                      <dt className="font-medium text-gray-500">Tracking Code</dt>
                      <dd className="mt-1 text-gray-900">{order.trackingCode}</dd>
                    </div>
                  }
                  { order.comment &&
                    <div className="sm:col-span-2">
                      <dt className="font-medium text-gray-500">Kundenkommentar</dt>
                      <dd className="mt-1 text-gray-900">
                        {order.comment}
                      </dd>
                    </div>
                  }
                  <div className="sm:col-span-2">
                    <dt className="font-medium text-gray-500">Produkte</dt>
                    <OrderLineItemsListing items={order.items}/>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="font-medium text-gray-500">Transaktionen</dt>
                    <TransactionListing transactions={order.transactions}/>
                  </div>
                </dl>
              </div>
            </div>
          </section>

          {/* Comments*/}
          <Comments commentableId={order.id} comments={order.comments} currentUserId={currentUser.id} orderToken={orderToken}/>
        </div>

        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 space-y-5">
          <div className="bg-white py-5 shadow sm:rounded-lg">
            <h2 id="timeline-title" className="text-lg font-medium text-gray-900 pb-4 mb-4 border-b border-gray-200 px-4 sm:px-6">
              Kundendaten
            </h2>
            <div className="px-4 sm:px-6">
              {(order.customer &&
                <div className="text-gray-500 space-y-3">
                  <div>
                    <dt className="font-medium text-gray-900">Name</dt>
                    <dd className="text-gray-500">
                      {order.customer.firstname} {order.customer.lastname}
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">E-Mail</dt>
                    <dd className="text-gray-500">
                      {order.customer.email}
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">Kundennotiz</dt>
                    <CustomerComment
                      commentableId={order.customer.uuid}
                      comment={order.customer.comments[0]}
                    />
                  </div>
                </div>
              )}
              {(!order.customer &&
                <div className="text-gray-500">Der Kunde hat keine Angaben gemacht.</div>
              )}
            </div>
          </div>
          <div className="bg-white py-5 shadow sm:rounded-lg">
            <h2 id="timeline-title" className="text-lg font-medium text-gray-900 pb-4 mb-4 border-b border-gray-200 px-4 sm:px-6">
              Order Details
            </h2>
            <div className="px-4 sm:px-6 text-gray-500">
              <div className="flow-root space-y-3">
                <div>
                  <dt className="font-medium text-gray-900">Erstellt</dt>
                  <dd className="text-gray-500">
                    <MomentDate as="span" date={order.createdAt} format="DD. MMMM YYYY"/> um <MomentDate as="span" date={order.createdAt} format="HH:mm"/>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">Aktualisiert</dt>
                  <dd className="text-gray-500">
                    <MomentDate as="span" date={order.updatedAt} format="DD. MMMM YYYY"/> um <MomentDate as="span" date={order.updatedAt} format="HH:mm"/>
                  </dd>
                </div>
                { order.placedAt &&
                  <div>
                    <dt className="font-medium text-gray-900">Bestellt</dt>
                    <dd className="text-gray-500">
                      <MomentDate as="span" date={order.placedAt} format="DD. MMMM YYYY"/> um <MomentDate as="span" date={order.placedAt} format="HH:mm"/>
                    </dd>
                  </div>
                }
              </div>
            </div>
          </div>
          {( ['pending', 'fullfilled'].includes(order.status) &&
            <div className="bg-white py-5 shadow sm:rounded-lg">
              <h2 id="timeline-title" className="text-lg font-medium text-gray-900 pb-4 mb-4 border-b border-gray-200 px-4 sm:px-6">
                Erstattung
              </h2>
              <div className="px-4 sm:px-6 text-gray-500">
                <OrderRefund orderToken={orderToken}/>
              </div>
            </div>
          )}
        </section>
      </div>
    </main>
  )
}

// class Order extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       trackingCode: null
//     }
//
//     this.onUpdateTrackingCode = this.onUpdateTrackingCode.bind(this);
//   }
//
//   onUpdateTrackingCode(e) {
//     const value = e.target.value;
//
//     this.setState({
//       trackingCode: value
//     })
//   }
//
//   render() {
//     const { token } = this.props.match.params;
//     const { trackingCode } = this.state;
//
//     return (
//       <Query query={ORDER_QUERY} variables={{ token }} fetchPolicy="network-only">
//         {(result) => {
//           if (result.loading) {
//             return <Loading/>;
//           }
//
//           if (result.error) {
//             return (
//               <div>
//                 <h2>Fehler:</h2>
//                 <div>{result.error}</div>
//               </div>
//             )
//           }
//
//           const { order } = result.data;
//           const { customer, transactions, items, discountCode } = result.data.order;
//
//           const orderToken = order.token;
//
//           const confirmRefund = (mutation) => {
//             const result = window.confirm("Bist du sicher, dass du die Bestellung erstatten willst?");
//             if (result === true) {
//               mutation();
//             }
//           }



// {( discountCode &&
//   <ContentSection title="Coupon Code">
//     <b>Code: {order.discountCode.code}</b><br/>
//     Ersparnis: <Price price={order.savings}/> {( order.discountCode.amountInPercent && `/ -${order.discountCode.amountInPercent}%`)}
//   </ContentSection>
// )}
