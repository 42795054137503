import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client'

import CustomerModal from '../../components/customer/modal'
import CustomerSubscriptionActions from '../../components/customer-subscriptions/actions'
import Input from '../../components/input'
import Loading from '../../components/loading';
import MomentDate from '../../components/date';
import { NavLink } from 'react-router-dom'
import Price from '../../components/layout/price';
import Status from '../../components/status';
import Table from '../../components/table'
import Title from '../../components/title'
import { subscriptionFragment } from '../../fragments/subscription.js'

const CUSTOMER_SUBSCRIPTIONS_QUERY = gql`
  {
    subscriptions {
      ${subscriptionFragment}
    }
  }
`;

const CUSTOMER_SUBSCRIPTIONS_SEARCH_QUERY = gql`
  query SearchCustomerSubscriptions($query: String!){
    subscriptions: searchSubscriptions(query: $query) {
      ${subscriptionFragment}
    }
  }
`;

const CustomerSubscriptions = () => {
  const [searchQuery, setSearchQuery] = useState('')

  const { loading, error, data } = useQuery(searchQuery === '' ? CUSTOMER_SUBSCRIPTIONS_QUERY : CUSTOMER_SUBSCRIPTIONS_SEARCH_QUERY, {
    variables: { query: searchQuery },
  });
  if (loading) return <Loading/>
  if (error) return (
    <div>
      <h2>Fehler:</h2>
      <div>{error}</div>
    </div>
  )

  const renderContent = () => {
    if (searchQuery === '') {
      const subscriptions = data && data.subscriptions;
      const activeCustomerSubscriptions = subscriptions.filter(sub => sub.status === "active");
      const pausedCustomerSubscriptions = subscriptions.filter(sub => sub.status === "paused");
      const canceledCustomerSubscriptions = subscriptions.filter(sub => sub.status === "canceled");
      const pricePerMonth = activeCustomerSubscriptions.map(sub => (sub.priceInCents / sub.intervalInDays * 30)).reduce(function(pv, cv) { return pv + cv; }, 0);
      const averageInterval = parseInt(activeCustomerSubscriptions.map(sub => sub.intervalInDays).reduce((a, b) => a + b) / activeCustomerSubscriptions.length, 10);

      const stats = [
        { name: "Aktive Abos", stat: activeCustomerSubscriptions.length },
        { name: "Pausierte Abos", stat: pausedCustomerSubscriptions.length },
        { name: "Gekündigte Abos", stat: canceledCustomerSubscriptions.length },
        { name: "Umsatz auf 30 Tage", stat: <Price price={pricePerMonth}/> },
        { name: "Ø Intervall", stat: averageInterval },
      ]

      return (
        <div className="mb-8">
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
            {stats.map((item) => (
              <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
              </div>
            ))}
          </dl>
        </div>
      )
    }
  }

  return (
    <div className="">
      <Title>Windel-Abos</Title>
      {renderContent()}
      <Table
        searchable
        searchQuery={searchQuery}
        onSearchInputChange={setSearchQuery}
        searchInputPlaceholder="Suchbegriff hier eingeben. Bspw: Name"
        loading={loading}
        error={error}
        columns={[
          { label: 'Nummer', className: "font-semibold text-gray-900" },
          { label: 'Status' },
          { label: 'Kunde' },
          { label: 'Intervall' },
          { label: 'Nächster Versand' },
          { label: 'Preis' },
          { label: 'Produkte' },
          { label: 'Aktionen', className: "last:text-left" }
        ]}
        items={data?.subscriptions}
        renderItem={(item) => {
          return [
            { content: item.id },
            { content: <Status status={item.status}/> },
            { content: <CustomerModal id={item.customer.id} buttonLabel={`${item.customer.firstname} ${item.customer.lastname}`} buttonClassName="text-gray-500 hover:underline"/> },
            { content: `${item.intervalInDays} Tage` },
            { content: <MomentDate date={item.nextTriggerAt}/> },
            { content: <Price price={item.priceInCents}/> },
            { content: item.items.map(item => (
              <div key={item.id}><small>{item.quantity}x {item.productVariant.sku}</small></div>
            )) },
            { content: <CustomerSubscriptionActions subscription={item}/> }
          ]
        }}
      />
    </div>
  )
}

  // <>
  //   <tr className={styles.customerSubscription}>
  //     <td className={styles.number}>
  //
  //     </td>
  //     <td className={styles.status}>
  //
  //     </td>
  //     <td className={styles.customer}>

  //     </td>
  //     <td className={styles.interval}>
  //
  //     </td>
  //     <td className={styles.nextTriggerAt}>
  //
  //     </td>
  //     <td className={styles.priceInCents}>
  //
  //     </td>
  //     <td>
  //       <Button
  //         onClick={placeOrder}
  //         size="small"
  //         confirm
  //         confirmMessage={`Bestellung ausführen? Dem Kunden wird der Betrag abgebucht und das Lieferdatum wird zurückgesetzt.`}
  //         loading={loading}
  //       >Bestellung ausführen</Button>
  //     </td>
  //     <td className={styles.products}>
  //
  //     </td>
  //   </tr>
  // </>

export default CustomerSubscriptions;
