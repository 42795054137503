import React from 'react';

const styles = {}

const translation = (status) => {
  switch (status) {
    case 'active':
      return 'Aktiv';
    case 'paused':
      return 'Pausiert';
    case 'canceled':
      return 'Beendet';
    default:
      return 'Entwurf';
  }
}

const KindergartenSubscriptionStatus = ({ status='Undefined' }) => <div className={styles.status} data-status={status.toLowerCase()}>{translation(status)}</div>;

export default KindergartenSubscriptionStatus;
