import { ADD, NotificationProvider, useNotificationContext } from '../../contexts/notification-context'
import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'

import { ChevronDownIcon } from '@heroicons/react/solid'

export default function GiftCardActions({ giftcard }) {
  const onDownloadRechnung = () => {
    window.open(giftcard.receiptUrl, '_blank').focus();
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="border border-transparent text-sm font-medium rounded-md inline-flex items-center justify-center px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-500 text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500">
          Aktionen
          <ChevronDownIcon
            className="ml-2 -mr-1 h-5 w-5 text-white hover:text-indigo-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75 z-4"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-indigo-500 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={onDownloadRechnung}
                >
                  Download Rechnung
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
