import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import { ORDER_QUERY } from '../../containers/order'
import { classNames } from '../../helpers'

const ADD_COMMENT_MUTATION = gql`
  mutation AddComment($commentableId: ID!, $body: String!) {
    createComment(input: { commentableId: $commentableId, body: $body }) {
      comment {
        id
        body
        createdAt
        user {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export default function CommentForm({ commentableId, orderToken }) {
  const [newBody, setNewBody] = useState("")
  const [createComment, { loading, data, error }] = useMutation(ADD_COMMENT_MUTATION)

  const onFormSubmit = (e) => {
    e.preventDefault()
    createComment({
      variables: { commentableId, body: newBody },
      update: (cache, { data: { createComment } }) => {
        const { comment } = createComment

        const data = cache.readQuery({
          query: ORDER_QUERY,
          variables: { token: orderToken }
        });

        const newData = {
          ...data,
          order: {
            ...data.order,
            comments: [
              ...data.order.comments,
              comment
            ]
          }
        }

        cache.writeQuery({
          query: ORDER_QUERY,
          data: newData,
          variables: { token: orderToken }
        });
        setNewBody("")
      }
    })
  }

  return (
    <div className="bg-gray-50 px-4 py-6 sm:px-6">
      <div className="flex space-x-3">
        <div className="min-w-0 flex-1">
          <form onSubmit={onFormSubmit}>
            <div>
              <label htmlFor="comment" className="sr-only">
                About
              </label>
              <textarea
                value={newBody}
                onChange={(e) => setNewBody(e.target.value)}
                id="comment"
                name="comment"
                rows={3}
                className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                placeholder="Notiz hier eintragen"
              />
            </div>
            <div className="mt-3 flex items-center justify-between">
              <button
                type="submit"
                className={classNames(
                  "inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-500",
                  newBody.length === 0 && "pointer-events-none bg-gray-100",
                  newBody.length > 0 && "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                )}
                disabled={newBody.length === 0}
              >
                Hinzufügen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
