import CustomerModalContent from './modal-content'
import Modal from '../modal'
import React from 'react'

const CustomerModal = ({ id, buttonLabel, children, buttonClassName }) => {
  return (
    <Modal
      title="Kundeninformationen"
      buttonLabel={buttonLabel}
      buttonClassName={buttonClassName}
    >
      <CustomerModalContent id={id}/>
    </Modal>
  )
}

export default CustomerModal
