import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import Modal from '../modal'
import { ORDER_QUERY } from '../../containers/order'

const CREATE_COMMENT_TO_CUSTOMER_MUTATION = gql`
  mutation CreateCommentToCustomer($commentableId: ID!, $body: String!) {
    createComment(input: { commentableId: $commentableId, body: $body }) {
      comment {
        id
        body
      }
    }
  }
`;

const UPDATE_COMMENT_TO_CUSTOMER_MUTATION = gql`
  mutation UpdateCommentToCustomer($id: ID!, $body: String!) {
    updateComment(input: { id: $id, body: $body }) {
      comment {
        id
        body
      }
    }
  }
`;


export default function CustomerComment({ comment, commentableId }) {
  const [displayBody, setDisplayBody] = useState(comment?.body || '')
  const [body, setBody] = useState(comment?.body || '')
  const [editing, setEditing] = useState(false)
  const [addComment, { loading, error }] = useMutation(comment ? UPDATE_COMMENT_TO_CUSTOMER_MUTATION : CREATE_COMMENT_TO_CUSTOMER_MUTATION)

  const variables = comment ? { id: comment.uuid, body } : { commentableId, body }

  const onFormSubmit = (e) => {
    e.preventDefault()
    addComment({
      variables,
      update: (cache, { data }) => {
        const newComment = comment ? data.updateComment.comment : data.createComment.comment
        setDisplayBody(newComment.body)
        setEditing(false)
      }
    })
  }

  if (!editing) {
    return (
      <dd>
        <div className="text-gray-500 whitespace-pre-wrap">{displayBody || "Keine Notiz"}</div>
        <button className="mt-4 text-sm text-indigo-500 hover:underline font-medium" onClick={() => setEditing(true)}>Bearbeiten</button>
      </dd>
    )
  }

  return (
    <>
    <form className={`space-y-3 ${loading && 'pointer-events-none opacity-5'}`} onSubmit={onFormSubmit}>
      <div>
        <div className="mt-1">
          <textarea
            rows={5}
            value={body}
            id="cpde"
            name="code"
            type="text"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={(e) => setBody(e.target.value)}
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Speichern
        </button>
      </div>
    </form>
    <button
      className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-gray-900 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      onClick={() => {
        setEditing(false)
        setBody(comment?.body || '')
      }}
    >
      Abbrechen
    </button>
    </>
  )
}
