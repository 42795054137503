import "flatpickr/dist/themes/airbnb.css";

import Flatpickr from "react-flatpickr";
import React from 'react';

const styles = {}

const Input = ({ label, value, onChange, type='text', help, placeholder, id=null }) => {
  const input = type === 'date' ? (
    <Flatpickr
      data-enable-time
      value={value}
      onChange={(date) => {
        onChange(date[0]);
      }}
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      options={
        {
          time_24hr: true
        }
      }
    />
  ) : (
    <input className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" defaultValue={value} onChange={(e) => onChange(e.target.value)} type={type} placeholder={placeholder}/>
  );

  return (
    <div className={styles.wrapper}>
      {( label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label> )}
      {input}
      {( help && <div className="text-sm text-gray-400 mt-1 ml-3">{help}</div>)}
    </div>
  )
}

export default Input;
