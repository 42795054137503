import "./index.css"

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
  useQuery
} from "@apollo/client"

import App from './containers/app/app';
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { createHttpLink } from 'apollo-link-http';
import { init } from '@sentry/browser';
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { setContext } from 'apollo-link-context';

if (process.env.REACT_APP_ENV !== 'development') {
  init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_ENV
  });
}

// create history
const history = createHistory();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_CASHIER_URL
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('cashierUserToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

render(
  <Router history={history}>
    <ApolloProvider client={client}>
      <App/>
    </ApolloProvider>
  </Router>, document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
