import React from 'react';

export default function Transaction({ item }) {
  const buildOptions = () => {
    if (item.productVariant.options && item.productVariant.options.length > 0) {
      const string = item.productVariant.options.map(option => `${option.name} - ${option.value}`).join(', ');
      return (
        <div className="text-gray-500"><b>Optionen:</b> {string}</div>
      );
    } else {
      return null;
    }
  }
  return (
    <tr key={item.id} className="hover:bg-gray-100 even:bg-gray-50">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
      {item.productVariant.product.name}
      {buildOptions()}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {item.productVariant.sku}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {item.quantity}
      </td>
    </tr>
  )
}
