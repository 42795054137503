import React from 'react'

export default function Title ({ children, actions=[] }) {
  return (
    <div className="mb-5 sm:flex sm:items-center sm:justify-between">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{children}</h3>
      { actions.length > 0 &&
        <div className="mt-3 flex sm:mt-0 sm:ml-4 space-x-3">
          {actions.map((action, index) => {
            return {...action, key: index}
          })}
        </div>
      }
    </div>
  )
}
