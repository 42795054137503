import KindergartenSubscriptionStatus from './status';
import MomentDate from '../date';
import React from 'react';

const styles = {}

const KindergartenSubscriptionListing = ({ subscriptions=[] }) => {

  if (subscriptions.length === 0) {
    return (
      <div>Es wurden noch keine Kiga-Abos abgeschlossen.</div>
    )
  }

  const items = subscriptions.map(subscription => (
    <div key={subscription.token} className={styles.item}>
      <div className={styles.token}>
        {subscription.token}<br/>
        <KindergartenSubscriptionStatus status={subscription.status}/>
      </div>
      <div className={styles.customer}>
        {subscription.customer.firstname} - {subscription.customer.lastname}<br/>
        <small>{subscription.customer.email}</small>
      </div>
      <div className={styles.child}>
        {subscription.childName}<br/>
        <small>{subscription.groupName}</small>
      </div>
      <div className={styles.kindergarten}>
         {subscription.kindergarten.name}<br/>
          <small>{subscription.kindergarten.city}</small>
      </div>
      <div className={styles.size}>
        {subscription.productVariant.metafields.find(f => f.key === 'Name').value}
      </div>
      <div className={styles.created}>
        <small><MomentDate date={subscription.createdAt}/></small>
      </div>
    </div>
  ))

  return (
    <div className={styles.items}>
      <div className={styles.item}>
        <div className={styles.token}>Token</div>
        <div className={styles.customer}>Kunde</div>
        <div className={styles.child}>Kind</div>
        <div className={styles.kindergarten}>Kindergarten</div>
        <div className={styles.size}>Größe</div>
        <div className={styles.created}>Abgeschlossen</div>
      </div>
      {items}
    </div>
  )
}

export default KindergartenSubscriptionListing;
