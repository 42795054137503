export const subscriptionFragment = `
  id
  intervalInDays
  nextTriggerAt
  priceInCents
  customer {
    id
    firstname
    lastname
  }
  items {
    id
    quantity
    productVariant {
      id
      sku
      product {
        id
        name
      }
    }
  }
  status
  priceInCents
`
